/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// TODO: use query params to save progress instead of redux
export interface SavedProgressState {
  savedPath: string | null; // save path to return to after login/signup
  savedFollow: string | null; // save cluster to follow after login/signup
}

const initialState: SavedProgressState = {
  savedPath: null,
  savedFollow: null,
};

export const savedProgressSlice = createSlice({
  name: "savedProgress",
  initialState,
  reducers: {
    setSavedPath: (state, action: PayloadAction<string | null>) => {
      state.savedPath = action.payload;
    },
    setSavedFollow: (state, action: PayloadAction<string | null>) => {
      state.savedFollow = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSavedPath, setSavedFollow } = savedProgressSlice.actions;

export default savedProgressSlice.reducer;
