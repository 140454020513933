const Separator = ({ children }) => {
  return (
    <div className="flex items-center my-4">
      <div className="border-b border-[#979797] w-full" />
      <span className="px-[10px] text-[#979797] text-xs">{children}</span>
      <div className="border-b border-[#979797] w-full" />
    </div>
  );
};

export default Separator;
