type AsterixIconProps = {
  className?: string;
};

const Asterix = ({ className }: AsterixIconProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="79"
    viewBox="0 0 80 79"
  >
    <path
      className={className}
      fillRule="evenodd"
      d="M68.43 10.267l-2.703-2.434c-.553-.498-1.39-.498-1.942 0L42.591 26.989 30.997.855c-.301-.673-1.06-1.013-1.761-.784l-3.475 1.13c-.704.227-1.12.95-.965 1.67l5.998 27.95-28.441-3.03c-.734-.078-1.41.412-1.563 1.135l-.759 3.558c-.153.73.272 1.455.983 1.676l27.352 8.569-17.028 23.36c-.424.58-.337 1.39.194 1.87l2.769 2.49c.532.482 1.344.48 1.877 0L37.43 51.261 49.02 77.42c.295.66 1.038.995 1.73.771l3.522-1.146c.688-.223 1.098-.93.945-1.638l-5.983-27.98 28.423 3.035c.727.077 1.395-.408 1.546-1.121l.766-3.6c.15-.717-.261-1.43-.955-1.654l-27.202-8.8 16.822-23.09c.438-.601.35-1.436-.203-1.93"
    />
  </svg>
);

export default Asterix;
